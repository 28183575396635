import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang/i18n'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const dashboard = () => import('@/views/dashboard/dashboard')
const profile = () => import('@/views/profile')

const organization_company = () => import('@/views/organization/company')
const organization_user = () => import('@/views/organization/user')

const device_search = () => import('@/views/device/search')
const device_qcReport = () => import('@/views/device/qcReport')
const device_qrCode = () => import('@/views/device/qrCode')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: i18n.t('main'),
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('dashboard'),
          component: dashboard
        },
        {
          path: 'profile',
          name: i18n.t('profile'),
          component: profile
        },
        {
          path: 'organization',
          redirect: '/organization/user',
          name: i18n.t('organization'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'company',
              name: i18n.t('companies'),
              component: organization_company,
              meta: {
                role: 'root'
              }
            },
            {
              path: 'user',
              name: i18n.t('users'),
              component: organization_user,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'device',
          redirect: '/device/search',
          name: i18n.t('device'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'search',
              name: i18n.t('device_search'),
              component: device_search,
              meta: {
                role: 'device-search'
              }
            },
            {
              path: 'qcReport',
              name: i18n.t('qc_report'),
              component: device_qcReport,
              meta: {
                role: 'device-qcReport'
              }
            },
            {
              path: 'qrCode',
              name: i18n.t('qr_code'),
              component: device_qrCode,
              meta: {
                role: 'device-qrCode'
              }
            }
          ]
        },
      ]
    },
    {
      path: '*',
      redirect: '/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

