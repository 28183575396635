import request from '@/utils/request'

export function getCompanies (data) {
  return request({
    url: '/auth/api/v1/company/getCompanies',
    method: 'post',
    data: data
  })
}

export function storeCompany (data) {
  return request({
    url: '/auth/api/v1/company/storeCompany',
    method: 'post',
    data: data
  })
}

export function updateCompany (data) {
  return request({
    url: '/auth/api/v1/company/updateCompany',
    method: 'post',
    data: data
  })
}

export function updateCompanyApikey (data) {
  return request({
    url: '/auth/api/v1/company/updateCompanyApikey',
    method: 'post',
    data: data
  })
}
