import request from '@/utils/request'
import md5 from 'md5'

export function login (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/auth/api/v1/user/login',
    method: 'post',
    data: data
  })
}

export function logout () {
  return request({
    url: '/auth/api/v1/user/logout',
    method: 'post'
  })
}

export function checkSession () {
  return request({
    url: '/auth/api/v1/user/checkSession',
    method: 'post'
  })
}

export function updateUserProfile (data) {
  return request({
    url: '/auth/api/v1/user/updateUserProfile',
    method: 'post',
    data: data
  })
}

export function updateUserLocale (data) {
  return request({
    url: '/auth/api/v1/user/updateUserLocale',
    method: 'post',
    data: data
  })
}

export function updateUserPassword (data) {
  data.currentPassword = data.currentPassword ? md5(data.currentPassword).toUpperCase() : ''
  data.newPassword = data.newPassword ? md5(data.newPassword).toUpperCase() : ''
  data.repeatPassword = data.repeatPassword ? md5(data.repeatPassword).toUpperCase() : ''
  return request({
    url: '/auth/api/v1/user/updateUserPassword',
    method: 'post',
    data: data
  })
}

export function updateUserAvatar (data) {
  return request({
    url: '/auth/api/v1/user/updateUserAvatar',
    method: 'post',
    data: data
  })
}

export function getUsers (data) {
  return request({
    url: '/auth/api/v1/user/getUsers',
    method: 'post',
    data: data
  })
}

export function storeUser (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/auth/api/v1/user/storeUser',
    method: 'post',
    data: data
  })
}

export function updateUser (data) {
  data.password = data.password ? md5(data.password).toUpperCase() : ''
  return request({
    url: '/auth/api/v1/user/updateUser',
    method: 'post',
    data: data
  })
}
