import request from '@/utils/request'

export function getDevices (data) {
  data.devid = data.devid ? data.devid.replace(/\s+/g, '').replace(/,+/g, ',').replace(/^,|,$/g, ''): ''
  data.devid = data.devid.split(',').length > 1 ? data.devid.split(',') : data.devid
  return request({
    url: '/maintain/api/v1/device/getDevices',
    method: 'post',
    data: data
  })
}

export function updateDevice (data) {
  return request({
    url: '/maintain/api/v1/device/updateDevice',
    method: 'post',
    data: data
  })
}

export function updateDeviceBoxNumber (data) {
  return request({
    url: '/maintain/api/v1/device/updateDeviceBoxNumber',
    method: 'post',
    data: data
  })
}

export function getQcItems () {
  return request({
    url: '/maintain/api/v1/device/getQcItems',
    method: 'post'
  })
}

export function getQcReports (data) {
  data.devid = data.devid ? data.devid.replace(/\s+/g, '').replace(/,+/g, ',').replace(/^,|,$/g, ''): ''
  data.devid = data.devid.split(',').length > 1 ? data.devid.split(',') : data.devid
  return request({
    url: '/maintain/api/v1/device/getQcReports',
    method: 'post',
    data: data
  })
}

export function getLatestQcReport (data) {
  return request({
    url: '/maintain/api/v1/device/getLatestQcReport',
    method: 'post',
    data: data
  })
}

export function getQrCodes (data) {
  return request({
    url: '/maintain/api/v1/device/getQrCodes',
    method: 'post',
    data: data
  })
}

export function createQrCodes (data) {
  return request({
    url: '/maintain/api/v1/device/createQrCodes',
    method: 'post',
    data: data
  })
}
