import * as companyApi from '@/api/auth/company'

const company = {
  state: {
  },
  mutations: {
  },
  actions: {
    getCompanies ({ commit }, data) {
      return new Promise((resolve, reject) => {
        companyApi.getCompanies(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeCompany ({ commit }, data) {
      return new Promise((resolve, reject) => {
        companyApi.storeCompany(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateCompany ({ commit }, data) {
      return new Promise((resolve, reject) => {
        companyApi.updateCompany(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateCompanyApikey ({ commit }, data) {
      return new Promise((resolve, reject) => {
        companyApi.updateCompanyApikey(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default company
