export default {
  search_info: '最後搜尋於 {at}，共有 {count} 項結果（搜尋時間：{sec}秒）',
  delete_note: '注意：此操作無法恢復',
  delete_msg: '您確定要刪除 <b>{name}</b> 嗎？',

  number_of_orders_msg: '{count} 筆',
  number_of_coupons_msg: '{count} 張',
  number_of_frequency_msg: '{count} 次',

  pick_a_date_or_date_range: '選擇一個日期或日期範圍',

  incorrect_email_or_password: '錯誤的Email或密碼',
  wrong_format: '格式錯誤',
  mobile_number_has_been_registered: '手機號碼已被註冊',
  try_again_later: '稍後再試',
  the_verification_code_is_incorrect_or_expired: '驗證碼不正確或已過期',

  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',

  jan: '1 月',
  feb: '2 月',
  mar: '3 月',
  apr: '4 月',
  may: '5 月',
  jun: '6 月',
  jul: '7 月',
  aug: '8 月',
  sep: '9 月',
  oct: '10 月',
  nov: '11 月',
  dec: '12 月',

  seconds: '秒',
  minutes: '分',
  hours: '時',
  days: '日',
  weeks: '週',
  months: '月',
  years: '年',

  second: '秒',
  minute: '分',
  hour: '時',
  day: '日',
  week: '週',
  month: '月',
  year: '年',

  msg0: '無異常',
  msg1: '掉電復位',
  msg2: '看門狗復位',
  msg3: '韌體異常復位',
  msg4: '程序異常復位',
  msg5: '看門狗復位2',
  msg6: 'BOR復位',
  msg7: '未知復位',
  msg20: '通訊模組無回應',
  msg21: '無SIM卡',
  msg22: '註冊SIM卡被拒絕',
  msg23: '註冊SIM卡超時',
  msg24: '網路註冊失敗',
  msg25: '註冊網路被拒絕',
  msg26: '註冊網路超時',
  msg27: '連接TCP失敗',
  msg28: '連接TCP超時',
  msg29: '服務器無回應',
  msg30: '未知異常',
  msg40: '進入PSM失敗',
  msg103: '數據丟失',

  on: '開',
  off: '關',
  login: '登入',
  logout: '登出',
  login_to_your_account: '登入您的帳戶',
  forgot_password: '忘記密碼？',
  lang: '語言',
  main: '主頁',
  dashboard: '儀表板',
  profile: '個人資料',
  name: '名稱',
  email: '電子郵件',
  phone: '電話',
  cellphone: '手機',
  telephone: '市話',
  mobile_number: '手機號碼',
  username: '使用者名稱',
  password: '密碼',
  change_password: '更改密碼',
  current_password: '當前密碼',
  new_password: '新密碼',
  repeat_password: '重複輸入密碼',
  generate_password_msg: '點擊右方按鈕以生成隨機密碼',
  leave_blank_if_not_modified: '如不修改則留空',
  edit: '編輯',
  save: '儲存',
  create: '新增',
  read: '讀取',
  update: '更新',
  delete: '刪除',
  enable: '啟用',
  disable: '禁用',
  visible: '可見',
  invisible: '不可見',
  action: '操作',
  detail: '詳細資訊',
  please_select_a: '請選擇一個',
  search_a: '搜尋一個',
  change_avatar: '變更頭像',
  created_successfully: '新增成功',
  updated_successfully: '更新成功',
  uploaded_successfully: '上傳成功',
  deleted_successfully: '刪除成功',
  create_time: '新增時間',
  update_time: '更新時間',
  last_update_time: '最近更新時間',
  last_enable_time: '最近啟用時間',
  last_status_change_time: '最近狀態變更時間',
  last_used_time: '最近使用時間',
  last_data_time: '最近資料時間',
  receive_time: '接收時間',
  required_fields_cannot_be_empty: '必填欄位不能為空',
  management: '管理',
  manager: '管理者',
  settings: '設定',
  initialization: '初始化',
  batch_initialization: '批量初始化',
  system_management: '系統管理',
  organization: '組織',
  roles: '權限',
  role_groups: '權限群組',
  users: '用戶',
  search: '搜尋',
  search_mode: '搜尋模式',
  create_user: '新增用戶',
  edit_user: '編輯用戶',
  user_name: '用戶名稱',
  create_role_group: '新增權限群組',
  edit_role_group: '編輯權限群組',
  set_roles: '設定權限',
  permission_denied: '權限不足',
  description: '描述',
  companies: '公司',
  company: '公司',
  operation_management: '營運管理',
  device:'設備',
  device_id: '設備ID',
  device_search:'設備查詢',
  maintain_status: '維護狀態',
  normal: '正常',
  repair: '維修',
  scrap: '廢品',
  last_parking_status: '最近停車狀態',
  car_in: '有車',
  car_out: '無車',
  firmware_version: '韌體版本',
  target_firmware_version: '目標韌體版本',
  none: '無',
  parsed_data: '解析數據',
  remote_address: '遠程地址',
  send_count: '發送計數',
  ir_count: '紅外計數',
  change_count: '車位變化計數',
  IMSI: 'IMSI',
  IMEI: 'IMEI',
  work_time: '工作時間(秒)',
  device_time: '設備時間',
  battery_voltage: '電池電壓(mV)',
  uplaod_reason: '上傳原因',
  CSQ: 'CSQ',
  RSRP: 'RSRP',
  net_time: '入網時間(秒)',
  response_time: '響應時間(秒)',
  geomagnetic_X: '地磁X',
  geomagnetic_Y: '地磁Y',
  geomagnetic_Z: '地磁Z',
  geomagnetism: '地磁',
  infrared: '紅外',
  car_probability: '有車概率(%)',
  sensor_four_dimensional_vector: '傳感器四維向量',
  capacitor_voltage: '電容電壓(mV)',
  charge_count: '充電計數',
  connection_time: '連接時間(秒)',
  PSM_time: 'PSM時間(秒)',
  SINR: 'SINR',
  power_consumption: '耗電量(mAS)',
  current_zero: '電流零點',
  dryness: '乾燥度',
  main_ip: '主IP',
  alternate_ip: '備用IP',
  port: '端口',
  rate_type: '概率表類型',
  geomagnetic_infrared_ratio: '地磁紅外比例',
  geomagnetic_change_threshold: '地磁變化閥值',
  infrared_change_threshold: '紅外變化閥值',
  geomagnetic_trigger_threshold: '地磁觸發閥值',
  geomagnetic_stability_threshold: '地磁穩定閥值',
  geomagnetic_stability_time: '地磁穩定時間',
  heartbeat_intervals: '心跳包間隔',
  initialization_option: '初始化選項',
  sensor_four_dimensional_vector_change_threshold: '傳感器四維向量變化閥值',
  imei_option: 'IMEI選項',
  start: '開始',
  next: '下一步',
  end: '結束',
  batch_action: '批量操作',
  batch_qc: '批量QC',
  batch_settings: '批量設定',
  batch_edit: '批量編輯',
  edit_device: '編輯設備',
  change_status: '變更狀態',
  changed: '已變更',
  pending_change: '待變更',
  upgrade_status: '升級狀態',
  upgraded: '已升級',
  pending_upgrade: '待升級',
  upgrading: '升級中',
  upgrade_failed: '升級失敗',
  reset: '重置',
  pending_reset: '待重置',
  qc_report: 'QC 報告',
  single_number: '單號',
  preview: '預覽',
  item_name: '項目名稱',
  result: '結果',
  net_data: '網路數據',
  update_net_data: '更新網路數據',
  lock_bands: '鎖定頻段',
  carriers: '電信業者',
  at_cmd: 'AT 指令',
  cmd: '指令',
  firmware: '韌體',
  upgrade_firmware: '升級韌體',
  batch_upgrade_firmware: '批量升級韌體',
  qr_code: 'QR Code',
  qr_code_id: 'QR Code ID',
  create_qr_code: '新增QR Code',
  bound_time: '綁定時間',
  valid: '有效',
  invalid: '無效',
  qty: '數量',
  SN: '序列號',
  NCC: 'NCC',
  download: '下載',
  batch_export_reports: '批量匯出QC報告',
  box_number: '箱號',
  export: '匯出',
  export_without_saving: '匯出不儲存',
  save_box_number_and_export: '儲存箱號並匯出',
  create_company: '新增公司',
  edit_company: '編輯公司',
  error_logs: '錯誤紀錄',
  error_code: '錯誤代碼',
  error_time: '錯誤時間',
  auto_initialization: '自動初始化',
  successfully: '成功',
  failed: '失敗',
  update_to_customer_setting: '更新至客戶設定值',
  data_date_range: '數據日期範圍',
  options: '選項',
  device_customer_settings: '設備客戶設定值',
  import_raw_data_file: '匯入原始數據文件',
  import: '匯入',
  upload_file: '上傳文件',
  default: '預設',
  high_chassis: '高底盤',
}
