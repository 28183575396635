import request from '@/utils/request'

export function getParsedData (data) {
  return request({
    url: '/nbiot/api/v1/device/getParsedData',
    method: 'post',
    data: data
  })
}

export function importRawData (data) {
  return request({
    url: '/nbiot/api/v1/device/importRawData',
    method: 'post',
    data: data
  })
}

export function getCustomSettings (data) {
  return request({
    url: '/nbiot/api/v1/device/getCustomSettings',
    method: 'post',
    data: data
  })
}

export function updateCustomSettings (data) {
  return request({
    url: '/nbiot/api/v1/device/updateCustomSettings',
    method: 'post',
    data: data
  })
}

export function getSetting (data) {
  return request({
    url: '/nbiot/api/v1/device/getSetting',
    method: 'post',
    data: data
  })
}

export function updateSetting (data) {
  return request({
    url: '/nbiot/api/v1/device/updateSetting',
    method: 'post',
    data: data
  })
}

export function autoInitialization (data) {
  return request({
    url: '/nbiot/api/v1/device/autoInitialization',
    method: 'post',
    data: data
  })
}

export function getErrorLogs (data) {
  return request({
    url: '/nbiot/api/v1/device/getErrorLogs',
    method: 'post',
    data: data
  })
}

export function getNetData (data) {
  return request({
    url: '/nbiot/api/v1/device/getNetData',
    method: 'post',
    data: data
  })
}

export function updateNetData (data) {
  return request({
    url: '/nbiot/api/v1/device/updateNetData',
    method: 'post',
    data: data
  })
}

export function updateNetDataLockedBands (data) {
  return request({
    url: '/nbiot/api/v1/device/updateNetDataLockedBands',
    method: 'post',
    data: data
  })
}

export function getFirmwareList () {
  return request({
    url: '/nbiot/api/v1/device/getFirmwareList',
    method: 'post'
  })
}

export function upgradeDeviceFirmware (data) {
  return request({
    url: '/nbiot/api/v1/device/upgradeDeviceFirmware',
    method: 'post',
    data: data
  })
}

export function getAtcmd (data) {
  return request({
    url: '/nbiot/api/v1/device/getAtcmd',
    method: 'post',
    data: data
  })
}

export function createAtcmd (data) {
  return request({
    url: '/nbiot/api/v1/device/createAtcmd',
    method: 'post',
    data: data
  })
}
