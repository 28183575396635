import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import getters from '@/store/getters'

import auth_role from '@/store/modules/auth/role'
import auth_user from '@/store/modules/auth/user'
import auth_company from '@/store/modules/auth/company'
import nbiot_device from '@/store/modules/nbiot/device'
import maintain_device from '@/store/modules/maintain/device'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth_role,
    auth_user,
    auth_company,
    nbiot_device,
    maintain_device,
  },
  state,
  mutations,
  getters
})
