import 'core-js/stable'
import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import '@/middleware/checkRoles'
import i18n from '@/lang/i18n'

// coreui
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from '@/assets/icons/icons'

// moment
import moment from 'moment'

// DatePicker
import DatePicker from 'vue2-datepicker'
import DatePickerLang from '@/lib/DatePicker/lang'

// vue-table-2
import { ClientTable } from 'vue-tables-2'
// import SortControl from '@/lib/VueTable2/SortControl'
import '@/lib/VueTable2/FilterStyle.css'

// vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// vue-loading-overlay
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// v-charts
// import VCharts from 'v-charts'

// coreui/vue-chartjs
// import CoreuiVueCharts from '@coreui/vue-chartjs'

// fortawesome
import { library, config, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// underscore.js
import underscore from 'vue-underscore'

// textarea-autosize
import TextareaAutosize from 'vue-textarea-autosize'

// vue-excel-xlsx
import VueExcelXlsx from 'vue-excel-xlsx'

// vue-print-nb
import Print from 'vue-print-nb'

// vue-cropper
// import VueCropper from 'vue-cropper'

// vue-signature-pad
// import VueSignaturePad from 'vue-signature-pad'

// vue-randomcolor
// import VueRandomColor from 'vue-randomcolor'

// gmap-vue
// import * as GmapVue from 'gmap-vue'

Vue.config.performance = true
Vue.config.productionTip = false
Vue.prototype.$log = console.log.bind(console)

// coreui
Vue.use(CoreuiVue)

// moment
moment.locale(localStorage.locale === 'tw' ? 'zh-tw' : 'en')
Vue.prototype.$moment = moment

// DatePicker
DatePicker.props.lang.default = DatePickerLang
Vue.component('date-picker', DatePicker)

// vue-table-2
Vue.use(ClientTable, {sortIcon: { base: 'fas', is: 'fa-sort', up: 'fa-sort-up', down: 'fa-sort-down' }}, false, 'bootstrap4', 'default')

// vue-sweetalert2
Vue.use(VueSweetalert2)

// vue-loading-overlay
Vue.use(Loading, { color: '#ff993f', backgroundColor: '#000000', loader: 'bars', width: 80, height: 80 })

// v-charts
// Vue.use(VCharts)

// coreui/vue-chartjs
// Vue.use(CoreuiVueCharts)

// fortawesome
library.add(fas, fab, far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
config.autoReplaceSvg = 'nest'
dom.watch()

// underscore.js
Vue.use(underscore)

// textarea-autosize
Vue.use(TextareaAutosize)

// vue-excel-xlsx
Vue.use(VueExcelXlsx)

// vue-print-nb
Vue.use(Print)

// vue-cropper
// Vue.use(VueCropper)

// vue-signature-pad
// Vue.use(VueSignaturePad)

// vue-randomcolor
// Vue.use(VueRandomColor)

// gmap-vue
/*Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAZ1L198049D5eRTvsAl2biSgauenYnT4s',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then disable the following:
  installComponents: true
})*/

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
