export default {
  search_info: 'Last search at {at}, a total of {count} results (search time: {sec} seconds)',
  delete_note: 'Note: This operation cannot be recovered.',
  delete_msg: 'Are you sure you want to delete <b>{name}</b> ?',

  number_of_orders_msg: '{count} orders',
  number_of_coupons_msg: '{count} coupons',
  number_of_frequency_msg: '{count} frequency',

  pick_a_date_or_date_range: 'Pick a date or date range',

  incorrect_email_or_password: 'Incorrect username or password',
  wrong_format: 'Wrong format',
  mobile_number_has_been_registered: 'Mobile number has been registered',
  try_again_later: 'Try again later',
  the_verification_code_is_incorrect_or_expired: 'The verification code is incorrect or expired',

  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thur',
  fri: 'Fri',
  sat: 'Sat',

  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',

  seconds: 'Seconds',
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',

  second: 'Second',
  minute: 'Minute',
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',

  msg0: 'Normal',
  msg1: 'Power failure reset',
  msg2: 'Ｗwatchdog reset',
  msg3: 'Firmware abnormal reset',
  msg6: 'BOR reset',
  msg20: 'Communication module has no response',
  msg21: 'No SIM card',
  msg22: 'Registered SIM card is rejected',
  msg23: 'Registered SIM card timeout',
  msg24: 'Network registration failed',
  msg25: 'Network registration rejected',
  msg26: 'Network registration timeout',
  msg27: 'Connection to TCP failed',
  msg28: 'Connection to TCP timeout',
  msg29: 'Server no response',
  msg30: 'Unknown exception',
  msg40: 'Failed to enter PSM',
  msg103: 'Data lost',

  on: 'On',
  off: 'Off',
  login: 'Log in',
  logout: 'Log out',
  login_to_your_account: 'Log in to your account.',
  forgot_password: 'Forgot password ?',
  lang: 'Language',
  main: 'Main',
  dashboard: 'Dashboard',
  profile: 'Profile',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  cellphone: 'Cellphone',
  telephone: 'Telephone',
  mobile_number: 'Mobile number',
  username: 'Username',
  password: 'Password',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  repeat_password: 'Repeat password',
  generate_password_msg: 'Click the button on the right to generate a random password',
  leave_blank_if_not_modified: 'Leave blank if not modified',
  edit: 'Edit',
  save: 'Save',
  create: 'Create',
  read: 'Read',
  update: 'Update',
  delete: 'Delete',
  enable: 'Enable',
  disable: 'Disable',
  visible: 'Visible',
  invisible: 'Invisible',
  action: 'Action',
  detail: 'Detail',
  please_select_a: 'Please select a ',
  search_a: 'Search a ',
  change_avatar: 'Change avatar',
  created_successfully: 'Created successfully',
  updated_successfully: 'Updated successfully',
  uploaded_successfully: 'Uploaded successfully',
  deleted_successfully: 'Deleted successfully',
  create_time: 'Create time',
  update_time: 'Update time',
  last_update_time: 'Last update time',
  last_enable_time: 'Last enable time',
  last_status_change_time: 'Last status change time',
  last_used_time: 'Last used time',
  last_data_time: 'Last data time',
  receive_time: 'Receive time',
  required_fields_cannot_be_empty: 'Required fields cannot be empty',
  management: 'Management',
  manager: 'Manager',
  settings: 'Settings',
  initialization: 'Initialization',
  batch_initialization: 'Batch initialization',
  system_management: 'System management',
  organization: 'Organization',
  roles: 'Permission',
  role_groups: 'Permission groups',
  users: 'Users',
  search: 'Search',
  search_mode: 'Search mode',
  create_user: 'Create user',
  edit_user: 'Edit user',
  user_name: 'User name',
  create_role_group: 'Create permission group',
  edit_role_group: 'Edit permission group',
  set_roles: 'Set permissions',
  permission_denied: 'Permission denied',
  description: 'Description',
  companies: 'Companies',
  company: 'Company',
  operation_management: 'Operation management',
  device:'Device',
  device_id: 'Device ID',
  device_search:'Device search',
  maintain_status: 'Maintain status',
  normal: 'Normal',
  repair: 'Repair',
  scrap: 'Scrap',
  last_parking_status: 'Last parking status',
  car_in: 'Car in',
  car_out: 'Car out',
  firmware_version: 'Firmware version',
  target_firmware_version: 'Target firmware version',
  none: 'None',
  parsed_data: 'Parsed data',
  remote_address: 'Remote address',
  send_count: 'Send count',
  ir_count: 'IR count',
  change_count: 'Change count',
  IMSI: 'IMSI',
  IMEI: 'IMEI',
  work_time: 'Work time (s)',
  device_time: 'Device time',
  battery_voltage: 'Battery voltage (mV)',
  uplaod_reason: 'Upload reason',
  CSQ: 'CSQ',
  RSRP: 'RSRP',
  net_time: 'Net time (s)',
  response_time: 'Response time (s)',
  geomagnetic_X: 'Geomagnetic X',
  geomagnetic_Y: 'Geomagnetic Y',
  geomagnetic_Z: 'Geomagnetic Z',
  geomagnetism: 'Geomagnetism',
  infrared: 'Infrared',
  car_probability: 'Car probability (%)',
  sensor_four_dimensional_vector: 'Sensor four dimensional vector',
  capacitor_voltage: 'Capacitor voltage (mV)',
  charge_count: 'Charge count',
  connection_time: 'Connection time (s)',
  PSM_time: 'PSM time(s)',
  SINR: 'SINR',
  power_consumption: 'Power consumption(mAS)',
  current_zero: 'Current zero',
  dryness: 'Dryness',
  main_ip: 'Main IP',
  alternate_ip: 'Alternate IP',
  port: 'Port',
  rate_type: 'Rate type',
  geomagnetic_infrared_ratio: 'Geomagnetic infrared ratio',
  geomagnetic_change_threshold: 'Geomagnetic change threshold',
  infrared_change_threshold: 'Infrared change threshold',
  geomagnetic_trigger_threshold: 'Geomagnetic trigger threshold',
  geomagnetic_stability_threshold: 'Geomagnetic stability threshold',
  geomagnetic_stability_time: 'Geomagnetic stability time',
  heartbeat_intervals: 'Heartbeat intervals',
  initialization_option: 'Initialization option',
  sensor_four_dimensional_vector_change_threshold: 'Sensor four dimensional vector change threshold',
  imei_option: 'IMEI option',
  start: 'Start',
  next: 'Next',
  end: 'End',
  batch_action: 'Batch action',
  batch_qc: 'Batch QC',
  batch_settings: 'Batch settings',
  batch_edit: 'Batch edit',
  edit_device: 'Edit device',
  change_status: 'Change status',
  changed: 'Changed',
  pending_change: 'Pending change',
  upgrade_status: 'Upgrade status',
  upgraded: 'Upgraded',
  pending_upgrade: 'Pending upgrade',
  upgrading: 'Upgrading',
  upgrade_failed: 'Upgrade failed',
  reset: 'Reset',
  pending_reset: 'Pending reset',
  qc_report: 'QC report',
  single_number: 'Single number',
  preview: 'Preview',
  item_name: 'Item name',
  result: 'Result',
  net_data: 'Net data',
  update_net_data: 'Update net data',
  lock_bands: 'Lock bands',
  carriers: 'Carriers',
  at_cmd: 'AT Command',
  cmd: 'Command',
  firmware: 'Firmware',
  upgrade_firmware: 'Upgrade firmware',
  batch_upgrade_firmware: 'Batch upgrade firmware',
  qr_code: 'QR Code',
  qr_code_id: 'QR Code ID',
  create_qr_code: 'Create QR Code',
  bound_time: 'Bound time',
  valid: 'Valid',
  invalid: 'Invalid',
  qty: 'Qty',
  SN: 'Serial number',
  NCC: 'NCC',
  download: 'Download',
  batch_export_reports: 'Batch export reports',
  box_number: 'Box number',
  export: 'Export',
  export_without_saving: 'Export without saving',
  save_box_number_and_export: 'Save box number and export',
  create_company: 'Create company',
  edit_company: 'Edit company',
  error_logs: 'Error logs',
  error_code: 'Error code',
  error_time: 'Error time',
  auto_initialization: 'Auto initialization',
  successfully: 'Successfully',
  failed: 'Failed',
  update_to_customer_setting: 'Update to customer setting',
  data_date_range: 'Data date range',
  options: 'Options',
  device_customer_settings: 'Device customer settings',
  import_raw_data_file: 'Import raw data file',
  import: 'Import',
  upload_file: 'Upload file',
  default: 'Default',
  high_chassis: 'High chassis',
}
