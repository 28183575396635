import router from '@/router'
import store from '@/store'
import i18n from '@/lang/i18n'
import { getSession } from '@/utils/auth'

router.beforeEach((to, from, next) => {
  if (getSession()) {
    if (to.path === '/login') {
      next({ path: to.query.redirect ? to.query.redirect : '/' })
    } else {
      if (!store.getters.roles || store.getters.roles.length === 0) {
        store.dispatch('checkSession').then(res => {
          if (!to.meta.role || store.getters.roles.includes('root') || store.getters.roles.includes(to.meta.role)) {
            next()
          } else {
            alert(i18n.t('permission_denied'))
            next({ path: '/' })
          }
        }).catch(e => {
          alert(e)
          store.dispatch('logout').then(res => {
            next({ path: '/' })
          })
        })
      } else {
        if (!to.meta.role || store.getters.roles.includes('root') || store.getters.roles.includes(to.meta.role)) {
          next()
        } else {
          alert(i18n.t('permission_denied'))
          next({ path: '/' })
        }
      }
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login', query: { redirect: to.path } })
    }
  }
})
