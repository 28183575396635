import * as deviceApi from '@/api/maintain/device'

const device = {
  state: {
  },
  mutations: {
  },
  actions: {
    getDevices ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getDevices(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateDevice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateDevice(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateDeviceBoxNumber ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateDeviceBoxNumber(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getQcItems ({ commit }) {
      return new Promise((resolve, reject) => {
        deviceApi.getQcItems().then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getQcReports ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getQcReports(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getLatestQcReport ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getLatestQcReport(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getQrCodes ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getQrCodes(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    createQrCodes ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.createQrCodes(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default device
