import * as roleApi from '@/api/auth/role'

const role = {
  state: {
  },
  mutations: {
  },
  actions: {
    getRoles ({ commit }) {
      return new Promise((resolve, reject) => {
        roleApi.getRoles().then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getRoleGroups ({ commit }) {
      return new Promise((resolve, reject) => {
        roleApi.getRoleGroups().then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default role
