import i18n from '@/lang/i18n'

export default function () {
  return {
    days: [i18n.t('sun'), i18n.t('mon'), i18n.t('tue'), i18n.t('wed'), i18n.t('thu'), i18n.t('fri'), i18n.t('sat')],
    months: [i18n.t('jan'), i18n.t('feb'), i18n.t('mar'), i18n.t('apr'), i18n.t('may'), i18n.t('jun'), i18n.t('jul'), i18n.t('aug'), i18n.t('sep'), i18n.t('oct'), i18n.t('nov'), i18n.t('dec')],
    placeholder: {
      date: i18n.t('pick_a_date_or_date_range'),
      dateRange: i18n.t('pick_a_date_or_date_range')
    }
  }
}
