import * as deviceApi from '@/api/nbiot/device'

const device = {
  state: {
  },
  mutations: {
  },
  actions: {
    getParsedData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getParsedData(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    importRawData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.importRawData(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getCustomSettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getCustomSettings(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateCustomSettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateCustomSettings(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getSetting ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getSetting(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateSetting ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateSetting(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    autoInitialization ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.autoInitialization(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getErrorLogs ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getErrorLogs(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getNetData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getNetData(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateNetData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateNetData(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateNetDataLockedBands ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.updateNetDataLockedBands(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getFirmwareList ({ commit }) {
      return new Promise((resolve, reject) => {
        deviceApi.getFirmwareList().then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    upgradeDeviceFirmware ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.upgradeDeviceFirmware(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getAtcmd ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.getAtcmd(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    createAtcmd ({ commit }, data) {
      return new Promise((resolve, reject) => {
        deviceApi.createAtcmd(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default device
