import Vue from 'vue'
import VueI18n from 'vue-i18n'

import tw from '@/lang/locale/tw'
import en from '@/lang/locale/en'

Vue.use(VueI18n)

const locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE || 'tw'

const i18n = new VueI18n({
  locale,
  messages: { tw, en }
})

export default i18n
