const getters = {
  session: state => state.auth_user.session,
  root: state => state.auth_user.root,
  roles: state => state.auth_user.roles,
  uid: state => state.auth_user.uid,
  avatar: state => state.auth_user.avatar,
  locale: state => state.auth_user.locale
}

export default getters
