import request from '@/utils/request'

export function getRoles () {
  return request({
    url: '/auth/api/v1/role/getRoles',
    method: 'post'
  })
}

export function getRoleGroups () {
  return request({
    url: '/auth/api/v1/role/getRoleGroups',
    method: 'post'
  })
}
